/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../services/API';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';
import { resgatarPremio } from '../../../store/modules/parceiro/actions';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

export default function DetalhePremio() {
  const dispatch = useDispatch();
  const { codigo } = useParams();
  const resgatando = useSelector((state) => state.parceiro.resgatando);
  const cartao = useSelector((state) => state.parceiro.cartao);
  const parceiro = useSelector((state) => state.parceiro);

  const [resgateLiberado, setResgateLiberado] = useState(null);
  const [premio, setPremio] = useState({
    nome: '',
    codigo: '',
    descricao: '',
    pontos: '',
    pontosAlternativo: '',
    diretorioFoto: '',
    tipo: '',
  });
  const [pontosBase, setPontosBase] = useState(0);
  const [qtde, setQtde] = useState(1);
  const [celularRecarga, setCelularRecarga] = useState('');
  const [operadoraRecarga, setOperadoraRecarga] = useState('');
  const [voltagem, setVoltagem] = useState('110V');
  const [nomeCompleto, setNomeCompleto] = useState('');

  const [confirmar, setConfirmar] = useState(false);

  const resgatar = async () => {
    let obs = null;
    if (premio.tipo === 'recarga') {
      if (!operadoraRecarga) {
        toast.error('Selecione a operadora', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setConfirmar(false);
        return;
      }
      if (!celularRecarga) {
        toast.error('Informe o celular', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setConfirmar(false);
        return;
      }
      obs = `${operadoraRecarga} - ${celularRecarga}`;
    }

    if (premio.tipo === 'bivolt') {
      obs = voltagem;
    }

    if (premio.tipo === 'credito' && !cartao) {
      if (!nomeCompleto) {
        toast.error('Informe seu nome completo', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setConfirmar(false);
        return;
      }
      obs = nomeCompleto;
    }

    setConfirmar(false);
    dispatch(
      resgatarPremio({
        codigoPremio: premio.codigo,
        qtde,
        obs,
      })
    );
  };

  const alterarVoltagem = (valor) => {
    setVoltagem(valor);
    valor === '110V'
      ? setPontosBase(premio.pontos)
      : setPontosBase(premio.pontosAlternativo);
  };

  const addQtde = (e) => {
    e.preventDefault();
    setQtde(qtde + 1);
  };

  const subQtde = (e) => {
    if (qtde === 1) return;
    e.preventDefault();
    setQtde(qtde - 1);
  };

  const validarCelular = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (!valor) return;
    if (valor.length < 10) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCelularRecarga('');
    }
  };

  const buscarPremio = async () => {
    const resultado = await api.get(`/api/premios?codigo=${codigo}&ativo=1`);
    if (resultado) {
      setPremio(resultado.data.premios[0]);
      setPontosBase(resultado.data.premios[0].pontos);
    }
  };

  const dadosRevenda = async () => {
    const resposta = await api
      .get(`/api/revendas/cnpj/${parceiro.cnpjRevenda}`, {
        validateStatus: false,
      })
      .catch((err) => console.log('Erro na consulta da revenda', err.message));

    if (resposta) {
      setResgateLiberado(resposta.data.resgateLiberado);
    }
  };

  useEffect(() => {
    buscarPremio();
    dadosRevenda();
  }, []);

  return (
    <Container className="mt-5 mb-5">
      {!confirmar && !resgatando ? (
        <Row>
          <Col xs={12} lg={5} className="p-3">
            <img
              className="w-100"
              alt={premio.nome}
              src={premio.diretorioFoto}
            ></img>
          </Col>
          <Col
            xs={12}
            lg={7}
            id="detalheProduto"
            name={premio.codigo}
            className="p-3"
          >
            <Col>
              <span id="nomeProduto" className="premio-titulo">
                {premio.nome}
              </span>
            </Col>
            <br />
            <Col>
              <span id="valorProduto" className="premio-subtitulo">
                <b>
                  {Number(pontosBase * qtde).toLocaleString('pt-BR')} pontos{' '}
                </b>
              </span>
            </Col>
            <br />
            <Col>
              <span className="premio-status">
                Disponível <i className="fas fa-check"></i>
              </span>
            </Col>
            <br />
            <Col>
              <span className="premio-subtitulo">
                <b>Descrição</b>
              </span>
            </Col>
            <Col className="text-justify">
              <span
                className="d-inline-block premio-subtitulo"
                dangerouslySetInnerHTML={{ __html: premio.descricao }}
              ></span>
            </Col>
            <br />
            {resgateLiberado !== 0 && (
              <Col className="text-justify">
                <button className="btn-sub-qtde m-1" onClick={subQtde}>
                  <i className="fas fa-minus"></i>
                </button>
                <span className="premio-subtitulo m-3">
                  <b>{qtde}</b>
                </span>
                <button className="btn-add-qtde m-1" onClick={addQtde}>
                  <i className="fas fa-plus"></i>
                </button>
              </Col>
            )}
            <br />
            {premio.tipo === 'recarga' && (
              <form className="form loja fundo-claro">
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="operadora">Operadora</label>
                  <select
                    id="operadora"
                    name="operadora"
                    placeholder="Operadora"
                    value={operadoraRecarga}
                    onChange={(e) => setOperadoraRecarga(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione a operadora
                    </option>
                    <option value="Vivo">Vivo</option>
                    <option value="Claro">Claro</option>
                    <option value="TIM">TIM</option>
                    <option value="OI">OI</option>
                    <option value="Nextel">Nextel</option>
                    <option value="Algar">Algar</option>
                  </select>
                </Col>
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="celular">Celular</label>
                  <InputMask
                    id="celular"
                    name="celular"
                    type="text"
                    placeholder="Celular"
                    value={celularRecarga}
                    mask="(99) 99999-9999"
                    onChange={(e) => setCelularRecarga(e.target.value)}
                    onBlur={validarCelular}
                  ></InputMask>
                </Col>
              </form>
            )}
            {premio.tipo === 'bivolt' && (
              <form className="form loja fundo-claro">
                <Col className="d-flex flex-column" xs={8} sm={6}>
                  <label htmlFor="voltagem">Voltagem</label>
                  <select
                    id="voltagem"
                    name="voltagem"
                    placeholder="Voltagem"
                    value={voltagem}
                    onChange={(e) => alterarVoltagem(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione a voltagem
                    </option>
                    <option value="110V">110V</option>
                    <option value="220V">220V</option>
                  </select>
                </Col>
              </form>
            )}

            {premio.tipo === 'credito' && !cartao && (
              <>
                <Col>
                  <span className="premio-subtitulo">
                    <b>Cartão Mastercard® Clube Gaya</b>
                  </span>
                </Col>

                <Col className="text-justify">
                  <span>
                    Você ainda não possui um cartão Mastercard® Clube Gaya.
                  </span>
                  <br />
                  <span>
                    Será enviado um envelope contendo o seu cartão Clube Ruby
                    Rose, já com o valor deste resgate.
                  </span>
                  <br />
                  <span>
                    Após desbloquear seu cartão guarde-o para seus próximos
                    resgates. É permitido 1 (um) cartão por CPF.
                  </span>
                  <br />
                  <span>
                    Os próximos resgates em dinheiro, serão creditados
                    automaticamente neste mesmo cartão.
                  </span>
                  <br />
                  <span>
                    Por medida de segurança seu cartão estará bloqueado, após
                    receber o envelope, siga as instruções para desbloquear o
                    seu cartão.
                  </span>
                  <br />
                  <span>
                    O desbloqueio do seu cartão pode levar até 24h úteis após a
                    solicitação.
                  </span>
                  <br />
                  <span>
                    Em caso de perda ou roubo, solicite o bloqueio do seu
                    cartão.
                  </span>
                  <br />
                  <br />
                  <span>
                    Importante verificar se o nome digitado é o do titular do
                    CPF cadastrado. Caso o nome esteja incorreto, a MASTERCARD®
                    solicitará os dados novamente.
                  </span>
                  <br />
                  <br />
                </Col>

                <form className="form loja fundo-claro">
                  <Col className="d-flex flex-column" xs={8} sm={6}>
                    <label for="nome">Digite seu nome</label>
                    <input
                      type="text"
                      id="nomeCompleto"
                      placeholder="Nome Completo"
                      value={nomeCompleto}
                      onChange={(e) => setNomeCompleto(e.target.value)}
                    ></input>
                  </Col>
                </form>
              </>
            )}

            {premio.tipo === 'credito' &&
              cartao &&
              cartao.numero &&
              !cartao.entregue && (
                <>
                  <Col>
                    <span className="premio-subtitulo">
                      <b>Cartão Mastercard® Clube Gaya</b>
                    </span>
                  </Col>

                  <Col className="text-justify">
                    <span>
                      SEU CARTÃO MASTERCARD® KG POINTS JÁ ESTÁ A CAMINHO.
                    </span>
                    <br />
                    <br />
                    <span>
                      Sua carga será depositada diretamente no cartão com final:{' '}
                      {cartao.numero.toString().slice(-4)}
                    </span>
                    <br />
                    <br />
                    <span>
                      Os próximos resgates em dinheiro serão creditados nesse
                      mesmo cartão. Continue resgatando normalmente.
                    </span>
                    <br />
                    <br />
                    <span>
                      Após desbloquear seu cartão guarde-o para seus próximos
                      resgates. É permitido 1 (um) cartão por CPF.
                    </span>
                    <br />
                    <br />
                    <span>Seu cartão é pessoal e intransferível.</span>
                  </Col>
                </>
              )}

            {premio.tipo === 'credito' &&
              cartao &&
              cartao.numero &&
              cartao.entregue && (
                <>
                  <Col>
                    <span className="premio-subtitulo">
                      <b>Cartão Mastercard® Clube Gaya</b>
                    </span>
                  </Col>

                  <Col className="text-justify">
                    <span>Você já possui um cartão Mastercard® Clube Gaya</span>
                    <br />
                    <br />
                    <span>
                      Sua carga será depositada diretamente no cartão com final:{' '}
                      {cartao.numero.toString().slice(-4)}
                    </span>
                    <br />
                    <br />
                    <span>
                      Após desbloquear seu cartão guarde-o para seus próximos
                      resgates. É permitido 1 (um) cartão por CPF.
                    </span>
                    <br />
                    <br />
                    <span>Seu cartão é pessoal e intransferível.</span>
                  </Col>
                </>
              )}
            {resgateLiberado !== 0 && (
              <Col className="pt-5">
                <button
                  className="btn-resgatar"
                  onClick={() => setConfirmar(true)}
                >
                  <i className="fas fa-shopping-cart"></i> RESGATAR
                </button>
              </Col>
            )}
          </Col>
        </Row>
      ) : null}
      {confirmar && !resgatando ? (
        <Row>
          <Col xs={12} lg={6} className="p-3">
            <img
              className="w-100"
              alt={premio.nome}
              src={premio.diretorioFoto}
            ></img>
          </Col>
          <Col
            xs={12}
            lg={6}
            name={premio.codigo}
            className="d-flex justify-content-center align-items-center p-3 flex-column"
          >
            <span className="p-2 text-center">
              Tem certeza que deseja resgatar{' '}
              <span className="red">
                <b>{`${qtde} x ${premio.nome}`}</b>
              </span>{' '}
              por{' '}
              <span className="red">
                <b>{Number(pontosBase * qtde).toLocaleString('pt-BR')}</b>{' '}
                pontos?
              </span>
            </span>
            <div className="text-center">
              <button className="btn btn-success m-2" onClick={resgatar}>
                <i className="fas fa-check"></i> SIM
              </button>
              <button
                className="btn btn-danger m-2"
                onClick={() => setConfirmar(false)}
              >
                <i className="fas fa-times"></i> NÃO
              </button>
            </div>
          </Col>
        </Row>
      ) : null}
      {resgatando && !confirmar ? (
        <Row>
          <Col xs={12} lg={6} className="p-3">
            <img
              className="w-100"
              alt={premio.nome}
              src={premio.diretorioFoto}
            ></img>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="d-flex justify-content-center align-items-center p-3 flex-column"
          >
            <span className="p-2">
              Resgatando <i className="fas fa-circle-notch fa-spin"></i>
            </span>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
}
