/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { Form, Input } from '@rocketseat/unform';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import historico from '../../../services/Historico';

export default function EsqueceuSenha(props) {
  const [enviando, setEnviando] = useState(false);
  const [informacao, setInformacao] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [uuidValidado, setUuidValidado] = useState(false);

  const { uuid } = props.match.params;

  useEffect(() => {
    document.title = 'Clube Gaya - Esqueceu a Senha - Painel';

    if (uuid) {
      validarUuid();
    }
  }, []);

  const validarUuid = async () => {
    const resultado = await api.post(
      '/api/sessoes/usuario/validar-recuperacao',
      {
        uuid,
      }
    );

    if (resultado) {
      toast.success(resultado.data.mensagem, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setUuidValidado(resultado.data.valido);
    } else {
      historico.push('/login');
    }
  };

  const recuperarSenha = async () => {
    if (!informacao) {
      toast.error('Informe seu email ou CPF primeiro', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    setEnviando(true);
    const resultado = await api.post('/api/sessoes/usuario/esqueceu-senha', {
      informacao,
    });

    if (resultado) {
      toast.success(resultado.data.mensagem, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    setEnviando(false);
  };

  const atualizarSenha = async () => {
    if (!novaSenha) {
      toast.error('Informe sua nova senha primeiro', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    setEnviando(true);
    const resultado = await api.post('/api/sessoes/usuario/atualizar-senha', {
      novaSenha,
      uuid,
    });

    if (resultado) {
      toast.success(resultado.data.mensagem, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      historico.push('/painel/');
    }
    setEnviando(false);
  };

  const formEmailCpf = (
    <Form onSubmit={recuperarSenha} className="form-login form-login-loja">
      <h5>ESQUECEU A SENHA?</h5>
      <label className="text-justify mt-2">
        Informe seu email de cadastro ou CPF. Se as informações estiverem
        corretas, você receberá um código para redefinir sua senha.
      </label>
      <label htmlFor="informacao" className="mt-4">
        Email ou CPF
      </label>
      <Input
        name="informacao"
        type="text"
        placeholder="Email ou CPF"
        value={informacao}
        onChange={(e) => setInformacao(e.target.value)}
      ></Input>
      {enviando ? (
        <button disabled className="btn-primario" type="submit">
          ENVIANDO...
        </button>
      ) : (
        <button className="btn-primario" type="submit">
          ENVIAR
        </button>
      )}
    </Form>
  );

  const formNovaSenha = (
    <Form onSubmit={atualizarSenha} className="form-login form-login-loja">
      <h5>ESQUECEU A SENHA?</h5>
      <label className="text-justify mt-2">Informe sua nova senha</label>
      <label htmlFor="novaSenha" className="mt-4">
        Nova senha
      </label>
      <Input
        name="novaSenha"
        type="password"
        placeholder="Nova senha"
        value={novaSenha}
        onChange={(e) => setNovaSenha(e.target.value)}
      ></Input>
      {enviando ? (
        <button disabled className="btn-primario" type="submit">
          ALTERANDO...
        </button>
      ) : (
        <button className="btn-primario" type="submit">
          ALTERAR
        </button>
      )}
    </Form>
  );

  const validandoUuid = (
    <Form className="form-login form-login-loja">
      <h5>ESQUECEU A SENHA?</h5>
      <span className="mt-3 mb-3 text-center">
        Validando link de recuperação, aguarde...{' '}
      </span>
      <div className="fa-2x text-center">
        <i className="fas fa-circle-notch fa-spin" />
      </div>
    </Form>
  );

  return (
    <div style={{ backgroundColor: '#d1d6ec' }}>
      <Header auto />
      <Container className="d-flex flex-column justify-content-center">
        <Row className="justify-content-center mt-5">
          <img
            className="w-100 form-logo"
            src="/imagens/american-burrs-painel.png"
            alt="Logo Clube Gaya"
          ></img>
        </Row>
        <Row className="justify-content-center mt-5 mb-5">
          {uuid && uuidValidado && formNovaSenha}
          {uuid && !uuidValidado && validandoUuid}
          {!uuid && formEmailCpf}
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
