import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import { autenticarUsuario } from '../../../store/modules/autenticacao/actions';
import { Link } from 'react-router-dom';

export default function Login() {
  const dispatch = useDispatch();
  const enviando = useSelector(
    (state) => state.autenticacao.autenticandoPainel
  );

  useEffect(() => {
    document.title = 'Clube Gaya - Login';
  }, []);

  const solicitarAutenticacao = ({ cpf, senha }) => {
    dispatch(autenticarUsuario(cpf, senha));
  };

  return (
    <div className="d-flex flex-column justify-content-center bg-black bg-full">
      <Container fluid>
        <Row className="flex-column align-items-center justify-content-center">
          <img
            className="w-100 form-login-logo"
            src="/imagens/participe_banner_home.jpg"
            alt="Logo Clube Gaya"
          ></img>
        </Row>
        <Row className="justify-content-center p-5 bg-black">
          <Form className="form-login" onSubmit={solicitarAutenticacao}>
            <h5>FAÇA SEU LOGIN</h5>
            <label>Entre com seus dados e acesse sua conta</label>
            <label htmlFor="cpf">CPF</label>
            <Input name="cpf" type="text" placeholder="CPF"></Input>
            <label htmlFor="senha">Senha</label>
            <Input name="senha" type="password" placeholder="Senha"></Input>
            {enviando ? (
              <button disabled className="btn-primario" type="submit">
                VALIDANDO...
              </button>
            ) : (
              <button className="btn-primario" type="submit">
                ENTRAR
              </button>
            )}
            <Link
              className="mb-3 mt-3 text-center link"
              to="/painel/esqueceu-senha"
            >
              Esqueceu sua senha?
            </Link>
          </Form>
        </Row>
      </Container>
    </div>
  );
}
